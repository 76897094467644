<template>
    <div class="stat-card">
      <div class="stat-header">
       <div class="wrap">
        <span class="stat-label">{{ label }}</span>
        <div class="tooltip-container">
          <span 
            class="info-icon" 
            @click="toggleTooltip"
          >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4087_137805)">
            <path d="M8.00065 14.6693C11.6825 14.6693 14.6673 11.6845 14.6673 8.0026C14.6673 4.32071 11.6825 1.33594 8.00065 1.33594C4.31875 1.33594 1.33398 4.32071 1.33398 8.0026C1.33398 11.6845 4.31875 14.6693 8.00065 14.6693Z" stroke="#AAADBA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.06055 6.00038C6.21728 5.55483 6.52665 5.17912 6.93385 4.9398C7.34105 4.70049 7.81981 4.61301 8.28533 4.69285C8.75085 4.7727 9.17309 5.01473 9.47726 5.37607C9.78144 5.7374 9.94792 6.19473 9.94721 6.66705C9.94721 8.00038 7.94721 8.66705 7.94721 8.66705" stroke="#AAADBA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 11.3359H8.00667" stroke="#AAADBA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4087_137805">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            
          </span>
          <div class="tooltip" :class="{ 'tooltip-visible': isTooltipVisible }">
            {{ tooltipText }}
            <div class="tooltip-arrow"></div>
          </div>
        </div>
       </div>
      </div>
      <div class="stat-value">
        <span v-if="prefix">{{ prefix }}</span>{{ formattedValue }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StatCard',
    props: {
      label: {
        required: true
      },
      value: {
        required: true
      },
      tooltipText: {
        required: true
      },
      prefix: {
        default: ''
      }
    },
    computed: {
      formattedValue() {
        if (!isNaN(this.value)) {
          // Convert to number, round to remove decimals, then format with commas
          return Math.round(Number(this.value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        return this.value
      }
    },
    data() {
      return {
        isTooltipVisible: false
      }
    },
    methods: {
      toggleTooltip() {
        this.isTooltipVisible = !this.isTooltipVisible
      },
      closeTooltip() {
        this.isTooltipVisible = false
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.closeTooltip()
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }
  </script>
  
  <style scoped>
  .stat-card {
    background: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction:column;
  
      }
  
  .stat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .wrap{
    display: flex;
    align-items: center;

  }
  
  .stat-label {
    color: hsla(228, 31%, 25%, 1);
    font-size: 16px;
    margin-right:5px;
  }
  
  .stat-value {
    font-size: 28px;
    font-weight: 600;
    color: hsla(228, 31%, 25%, 1);
  }
  
  .tooltip-container {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .info-icon {
    color: hsla(229, 10%, 70%, 1);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transform: translateY(-5px);
  }
  
  .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-8px);
    padding: 8px 12px;
    
    background-color: hsla(221, 43%, 11%, 1);
    color: white;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    z-index: 10;
  }
  
  .tooltip-visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-4px);
  }
  
  .tooltip-arrow {
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: hsla(221, 43%, 11%, 1);
  }
  
  @media (max-width: 768px) {
    .info-icon {
      padding: 8px;
      margin: -8px;
    }
    
    .tooltip {
      min-width: 200px;
      text-align: center;
    }
  }

   
  @media (max-width: 500px) {
  
    .tooltip {
      max-width: 200px;
       word-wrap: break-word; 
  white-space: normal; 
}
.stat-card {
    padding: 8px;
   
      }
.stat-value{
      transform: translateY(-20px);

    }
  }
  </style>
  
  