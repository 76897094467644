<template>
  <div class="dashboard-container">
    <div class="header">
      <div class="title-area">
        <h1>State bonus <span class="dot">•</span> {{ state }}</h1>
        <p class="subtitle">
          Keep track of registrations, subscriptions, and earned bonuses.
        </p>
      </div>

      <div class="dropdown-container">
        <div class="custom-select">
          <span class="dropdown-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5984 7.46094L11.1651 12.8943C10.5234 13.5359 9.47344 13.5359 8.83177 12.8943L3.39844 7.46094"
                stroke="#555C74"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <select name="" id="" v-model="month" @change="fetchFilteredData">
            <option
              v-for="(m, index) in Array.from({ length: 12 }, (_, i) => i + 1)"
              :key="index"
              :value="m"
            >
              {{ getMonthLabel(m) }} {{ selectedYear }}
            </option>
          </select>
          <span class="dropdown-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5984 7.46094L11.1651 12.8943C10.5234 13.5359 9.47344 13.5359 8.83177 12.8943L3.39844 7.46094"
                stroke="#555C74"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <!-- LOADER -->
    <div v-if="loading" class="loading-screen">
      <div class="spinner"></div>
      <p>Loading data, please wait...</p>
    </div>
    
    
    <!-- No Data Available -->
    <div
    v-else-if="!reward || reward === null"
    class="no-data"
    >
    <div class="divider"></div>
      No data available
    </div>

    <div v-else>
      <div class="stats-row">
       

          <Tooltip
          label="Total registration count"
        :value= this.totalRegistrations
        tooltipText="The number of people who registered up this month"
        prefix=""
          />

          <Tooltip
          label="Total bonus"
        :value= this.totalBonus
        tooltipText="Total amount of bonuses earned from all subscription this month"
        prefix=" ₦"
          />

          <Tooltip
          label="Total receiveable bonus"
        :value= this.totalReceivableBonus
        tooltipText="The amount you are entitled to after bonuses are shared this month"
        prefix=" ₦"
          />
      </div>
      <div class="divider"></div>

      <div class="table-wrapper">
        <table class="data-table">
          <thead>
            <tr>
              <th>Subscription type</th>
              <th>Total count</th>
              <!-- <th>Processing fee</th> -->
              <th>Bonus</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in subscriptionData" :key="index">
              <td>{{ item.type }}</td>
              <td>{{ Math.floor(item.count).toLocaleString() }}</td>

              <!-- Leave out processing fee for now -->
              <!-- <td>₦{{ Math.floor(item.fee).toLocaleString() }}</td> -->
              <td>₦{{ Math.floor(item.bonus).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import getPremiumData from "../services/premiumRewardData";
import Tooltip from "./Tooltip.vue";
export default {
  name: "StateBonusDashboard",
  components: {
    Tooltip,
  },
  data() {
    return {
      loading: false,
      totalRegistrations: 0,
      totalReceivableBonus: 0,
      totalBonus: 0,
      month: new Date().getMonth() + 1,
      state: "",
      fetchData: "",
      reward: null,
      subscriptionData: [],
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      months: [
        { label: "JAN", value: "01" },
        { label: "FEB", value: "02" },
        { label: "MAR", value: "03" },
        { label: "APR", value: "04" },
        { label: "MAY", value: "05" },
        { label: "JUN", value: "06" },
        { label: "JUL", value: "07" },
        { label: "AUG", value: "08" },
        { label: "SEP", value: "09" },
        { label: "OCT", value: "10" },
        { label: "NOV", value: "11" },
        { label: "DEC", value: "12" },
      ],
      years: [],

      tooltips: {
        registrationTooltip: false,
        bonusTooltip: false,
        receiveableTooltip: false
      },
    
      isMobile: false,
    };
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },

  methods: {
    getCurrentMonth() {
      return this.months[new Date().getMonth()];
    },

    toggleTooltip(tooltipName) {
      Object.keys(this.tooltips).forEach(key => {
        this.tooltips[key] = false
      })
      
      this.tooltips[tooltipName] = !this.tooltips[tooltipName]
    },
    closeAllTooltips() {
      Object.keys(this.tooltips).forEach(key => {
        this.tooltips[key] = false
      })
    },
    handleClickOutside(event) {
     
      
      // Close tooltips when clicking outside
      if (!event.target.closest('.info-icon')) {
        this.closeAllTooltips()
      }
    },

   
    filteredData(data) {
      this.subscriptionData = [
        {
          type: "Agent",
          count: data.reward.agent_count,
          fee: data.reward.agent_processing_fee,
          bonus: data.reward.agent_bonus,
        },
        {
          type: "Standard",
          count: data.reward.standard_count,
          fee: data.reward.standard_processing_fee,
          bonus: data.reward.standard_bonus,
        },
        {
          type: "Exclusive",
          count: data.reward.exclusive_count,
          fee: data.reward.exclusive_processing_fee,
          bonus: data.reward.exclusive_bonus,
        },

        {
          type: "Premium",
          count: data.reward.premium_count,
          fee: data.reward.premium_processing_fee,
          bonus: data.reward.premium_bonus,
        },
        {
          type: "Alliance",
          count: data.reward.alliance_count,
          fee: data.reward.alliance_processing_fee,
          bonus: data.reward.alliance_bonus,
        },
      ];

      this.totalBonus = data.total_bonus;
      this.totalReceivableBonus = data.total_receivable_bonus;
      this.totalRegistrations = data.total_reg;
      this.state = data.state.name;
    },

    fetchFilteredData() {
      let userID = this.$store.getters.getUser.id;

      const year = this.selectedYear;
      const month = this.month;
      this.loading = true;

      getPremiumData
        .filterPremiumRewardData(userID, year, month)
        .then((data) => {
          if (data?.reward) {
            this.filteredData(data);
            this.reward = data.reward;
          } else {
            this.reward = null
            console.log("filtered data not found");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          
          this.loading = false
        });
    },

    getMonthLabel(month) {
      const monthNames = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      return monthNames[month - 1]; // Adjust index since months start from 0
    },
  },

  computed: {
    availableMonths() {
      return this.months.filter(month => parseInt(month.value) <= this.month)
    }
  },


  created() {
    let userID = this.$store.getters.getUser.id;

    this.loading = true;
    getPremiumData
      .fetchPremiumRewardData(userID)
      .then((data) => {
        if (data) {
          this.reward = data.reward;
          this.filteredData(data);
          console.log("Reward", this.reward);
        } else {
        this.$toast.error('Reward data not found')
          console.log("Reward data not found");
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
/* EVERYTHING IN THIS COMPONENT SHOULD USE THE LATO FONT */
* {
  font-family: "Lato", sans-serif;
}

.dashboard-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px 0;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px 0;
}

.title-area h1 {
  font-size: 24px;
  font-weight: 600;
  color: #2b3352;
  margin-bottom: 10px;
}

.dot {
  color: #2b3352;
  margin: 0 5px;
}

.subtitle {
  color: #555c74;
  font-size: 16px;
  font-weight: 400;
}

.date-selector {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #d5d7da;
  border-radius: 8px;
  font-size: 14px;
  color: #aaadba;
  cursor: pointer;
}

.calendar-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.date-text {
  margin-right: 8px;
}

.dropdown-icon {
  display: flex;
  align-items: center;
}

/* Divider */
.divider {
  height: 1px;
  background-color: hsla(210, 5%, 92%, 1);
  margin: 20px 0;
}

/* Stats row */
.stats-row {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 35px;
  margin-bottom: 30px;
}



.stat-label {
  display: flex;
  align-items: center;
  color: hsla(226, 15%, 39%, 1);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.info-icon {
  margin-left: 5px;
  color: hsla(229, 10%, 70%, 1);
  cursor: pointer;
  display: inline-flex;
  position: relative;
}

.stat-value {
  font-size: 30px;
  font-weight: 600;
  color: hsla(228, 31%, 25%, 1);
}

/* Table styles */
.table-wrapper {
  padding: 0 35px;
  margin-bottom: 35px;
}

.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e9eaeb;
  border-radius: 8px;
  overflow: hidden;
}

.data-table th {
  background-color: #fafafa;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  font-size: 600;
  color: hsla(226, 15%, 39%, 1);
  border-bottom: 1px solid #e9eaeb;
}

.data-table td {
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #e9eaeb;
  color: hsla(226, 15%, 39%, 1);
}

.data-table tbody tr:hover {
  background-color: #fafafa;
}

.data-table tr:last-child td {
  border-bottom: none;
}

/* FIX TOOLTIP */

.tooltip-container {
  position: relative;
  display: inline-block;
  align-items: center;
}


.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
  padding: 12px 16px;
  min-width: 300px;
  text-align:center;
  background-color: hsla(221, 43%, 11%, 1);
  color: white;
  font-size: 12px;
  font-family:inherit;
  border-radius: 8px;
  
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
}

.tooltip-visible {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-4px);
}

.tooltip-arrow {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background-color: hsla(221, 43%, 11%, 1);
}

/* Responsiveness */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 15px;
    padding: 20px 20px 0;
  }

  .date-selector {
    align-self: flex-start;
  }

  .stats-row {
    flex-direction: column;
    padding: 0 10px;

     align-items: flex-start;
     justify-content: flex-start;
  gap: 5px;
  margin-bottom: 0;
  }

  .stat-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .table-wrapper {
    padding: 0 20px;
    overflow-x: auto;
  }

  .data-table {
    min-width: 600px;
  }
}

@media (max-width: 480px) {
  
.dashboard-container {
  max-width: 100%;
  
}
  .header {
    padding: 15px 15px 0;
  }

  .title-area h1 {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
  }

  .stats-row {
    padding: 0 15px;
  }

  .stat-value {
    font-size: 24px;
  }

  .table-wrapper {
    padding: 0 15px;
  }
}

/****************************DATE DROPDOWN************************************/

.dropdown-container {
  display: inline-block;
  margin-bottom: 20px;
}

.custom-select {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  width: 170px;
  background: #fff;
  cursor: pointer;
  position: relative;
}

select {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  background: transparent;
  color: #333;
  appearance: none; 
}

select:focus {
  outline: none;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

option {
  font-size: 14px;
  padding: 30px;
}

/* Loading Screen */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #555;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-screen p {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

.no-data{
  text-align: center ;
  color: #2b3352;
}
</style>
