import client from "./requestclient";

const getPremiumData = {
    fetchPremiumRewardData: async (id) => {
        if (!id) {
            console.error("Error: ID is undefined");
            return
        }
        return await client({ URL_PATH: `get-premiun-reward-data/${id}`, method: "GET" });

    },

    filterPremiumRewardData: async (id, year, month) => {
        if (!id) {
            console.error("Error: ID is undefined");
            return
        }
        const formattedMonth = month.toString().padStart(2, '0')
        return await client({ URL_PATH: `filter-get-premiun-reward-data/${id}/${year}/${formattedMonth}`, method: "GET" });

    }

    
}

export default getPremiumData
